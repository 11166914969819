import React from "react";
import { THEME } from "../../constants/Settings";

const PROGRAMS = React.lazy(
  () => import(`../../themes/${THEME}/pages/Programs/Program.page`)
);

const COMPARE_PROGRAMS = React.lazy(
  () => import(`../../themes/${THEME}/pages/Programs/ComparePrograms.page`)
);

export { PROGRAMS, COMPARE_PROGRAMS };
