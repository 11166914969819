import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { THEME } from "../constants/Settings";

import { SIGNIN } from "./authentication";
import { PROGRAMS, COMPARE_PROGRAMS } from "./inventory";
import RouteMiddleware from "../middlewares/RouteMiddleware";

const Dashboard = React.lazy(
  () => import(`../themes/${THEME}/pages/Dashboard.page`)
);

const Permissions = React.lazy(
  () => import(`../themes/${THEME}/pages/Permissions/Permission.page`)
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <RouteMiddleware>
          <Dashboard />
        </RouteMiddleware>
      </Suspense>
    ),
  },
  {
    path: "/signin",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <RouteMiddleware>
          <SIGNIN />
        </RouteMiddleware>
      </Suspense>
    ),
  },
  {
    path: "/permissions",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <RouteMiddleware>
          <Permissions />
        </RouteMiddleware>
      </Suspense>
    ),
  },
  {
    path: "/programs",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <RouteMiddleware>
          <PROGRAMS />
        </RouteMiddleware>
      </Suspense>
    ),
  },
  {
    path: "/programs/compare",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <RouteMiddleware>
          <COMPARE_PROGRAMS />
        </RouteMiddleware>
      </Suspense>
    ),
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
