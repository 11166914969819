import { useLocation } from "react-router-dom";
import useAuthProvider from "../hooks/useAuthProvider";

export default function RouteMiddleware({ children }: { children: any }) {
  const { pathname } = useLocation();
  const { isLoggedIn } = useAuthProvider();

  if (pathname === "/signin" && !isLoggedIn) {
    return children;
  }

  if (pathname === "/signup" && !isLoggedIn) {
    return children;
  }

  if (pathname === "/signin" && isLoggedIn) {
    window.location.href = "/";
  }

  if (pathname === "/signup" && isLoggedIn) {
    window.location.href = "/";
  }

  return children;
}
