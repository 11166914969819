import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware"; // required for devtools typing

import { roleSwitchedEvent } from "../../events/events";
import { IRoleState } from "../interfaces/RoleState.interface";

const useRoleState = create<IRoleState>()(
  persist(
    devtools(
      (set) => ({
        role: "1",
        setRole: (role: any) =>
          set(() => {
            window.dispatchEvent(roleSwitchedEvent);
            return { role };
          }),
      }),
      {
        enabled: true,
      }
    ),
    {
      name: "role",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const { getState: roleState } = useRoleState;
export default useRoleState;
