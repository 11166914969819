import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import useCookie from "./useCookie";
import errorHandler from "../helpers/handleError";
import { notifications } from "@mantine/notifications";
import useAuthState from "../models/states/useAuth";
import useRoleState from "../models/states/useRole";

export default function useAuthProvider() {
  const navigate = useNavigate();
  const [, updateSession] = useCookie("session", "");
  const [, updateRefreshToken] = useCookie("refresh-token", "");
  const { user, isLoggedIn, setUser, setIsLoggedIn } = useAuthState();
  const { role: activeRole } = useRoleState();

  const role = user?.roles.find((role) => role.id === Number(activeRole));

  async function login({
    api,
    credentials,
  }: {
    api: UseMutateAsyncFunction<AxiosResponse<any, any>, Error, any, unknown>;
    credentials: { email: string; password: string };
  }) {
    try {
      const res = await api(credentials);
      updateSession(res.data.token.accessToken);
      updateRefreshToken(res.data.token.refreshToken);

      setUser(res.data.data);
      setIsLoggedIn(true);

      navigate("/");
    } catch (error) {
      errorHandler(error);
    }
  }

  async function logout({
    api,
  }: {
    api: UseMutateAsyncFunction<AxiosResponse<any, any>, Error, void, unknown>;
  }) {
    try {
      const id = notifications.show({
        title: "Logging out",
        message: "",
        loading: true,
        autoClose: false,
      });

      await api();
      updateSession("");
      updateRefreshToken("");

      setUser(null);
      setIsLoggedIn(false);

      notifications.update({
        id,
        title: "Logged out",
        message: "",
        loading: false,
        autoClose: 2000,
      });

      return navigate("/signin");
    } catch (error) {
      errorHandler(error);
    }
  }

  return { user, role, isLoggedIn, login, logout };
}
