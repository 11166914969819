import { notifications } from "@mantine/notifications";

export default function errorHandler(error: any) {
  error.response.data?.errors?.forEach((_error: any) => {
    notifications.show({
      title: _error.message,
      message: "",
    });
  });
}
