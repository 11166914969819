import { create } from "zustand";
import CryptoJS from "crypto-js";
import { persist } from "zustand/middleware"; // required for devtools typing
import { IAuthState } from "../interfaces/AuthState.interface";

const useAuthState = create<IAuthState>()(
  persist(
    (set) => ({
      user: null,
      isLoggedIn: false,
      setIsLoggedIn: (isLoggedIn) => set({ isLoggedIn }),
      setUser: (user) => set({ user }),
    }),
    {
      name: "FM_AUTH_STATE",
      storage: {
        getItem: async (name) => {
          const str: any = localStorage.getItem(name);

          var bytes = CryptoJS.AES.decrypt(str, "secret key 123");
          var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

          return {
            state: {
              ...decryptedData.state,
            },
          };
        },
        setItem: async (name, newValue: any) => {
          const str = JSON.stringify({
            state: {
              ...newValue.state,
            },
          });

          var ciphertext = CryptoJS.AES.encrypt(
            str,
            "secret key 123"
          ).toString();

          localStorage.setItem(name, ciphertext);
        },
        removeItem: (name) => localStorage.removeItem(name),
      },
    }
  )
);

export const { getState: getAuthState, setState: setAuthState } = useAuthState;
export default useAuthState;
